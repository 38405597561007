import ReactGA from 'react-ga'

// initialise Google Analytics
export const initGA = (id: string) => {
  if (!id) return
  ReactGA.initialize(id)
}

// log a page view
export const logPageView = () => {
  ReactGA.set({ page: window?.location?.pathname })
  ReactGA.pageview(window?.location?.pathname)
}
